/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  Paper,
  Typography,
  Chip,
  Card,
  CardContent,
  Button,
  CardActions,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { FactViolationDto } from '@ink-ai/insight-service-sdk';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ViewDiff } from '../../../../common/components/ai-assistant/Compose/components/ViewDiff';
import CitationList from './CitationList';
import { copyText } from '@ink-ai/portal/common/utils';
import { LoadingButton } from '@mui/lab';

interface FactCheckingResultProps {
  factCheckViolationResult: FactViolationDto;
  index: number;
  onClick: () => void;
  onResolve: (index: number) => void;
  onAccept: (index: number) => boolean | Promise<boolean>;
}

const FactCheckingResult: React.FC<FactCheckingResultProps> = ({
  factCheckViolationResult,
  index,
  onClick,
  onResolve,
  onAccept,
}) => {
  const [expandReason, setExpandReason] = React.useState<string | null>(null);
  const [expandReasonIndex, setExpandReasonIndex] = React.useState<
    number | null
  >(null);
  const [copyTooltip, setCopyTooltip] = React.useState(false);
  const [acceptTooltip, setAcceptTooltip] = React.useState(false);
  const [acceptLoading, setAcceptLoading] = React.useState(false);

  const handleAccept = async (index: number) => {
    setAcceptLoading(true);
    try {
      const succeeded = await onAccept(index);
      if (succeeded) {
        onResolve(index);
      } else {
        copyText(factCheckViolationResult.fixedSentence);
        setAcceptTooltip(true);
        setTimeout(() => setAcceptTooltip(false), 3000);
      }
    } catch (err) {
      console.error('Failed to accept violation: ', err);
    } finally {
      setAcceptLoading(false);
    }
  };

  const handleCopy = () => {
    try {
      copyText(factCheckViolationResult.fixedSentence);
      setCopyTooltip(true);
      setTimeout(() => setCopyTooltip(false), 1500);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <>
      <Card
        key={index}
        sx={{
          marginY: 2,
          borderRadius: 4,
        }}
        onClick={() => {
          onClick();
        }}
      >
        <CardContent sx={{ textAlign: 'left' }}>
          <ViewDiff
            originalText={factCheckViolationResult.originalSentence}
            generatedText={factCheckViolationResult.fixedSentence}
          />

          <Box>
            {factCheckViolationResult.violationReasons?.map((item, index) => (
              <Chip
                key={index}
                size="small"
                icon={<InfoOutlinedIcon color="error" />}
                label={`Reason #${index + 1}`}
                onClick={() => {
                  if (expandReason === item) {
                    setExpandReason(null);
                    setExpandReasonIndex(null);
                  } else {
                    setExpandReason(item);
                    setExpandReasonIndex(index + 1);
                  }
                }}
                sx={{
                  cursor: 'pointer',
                  bgcolor: 'white',
                  border: '1px solid #D32F2F',
                  color: '#D32F2F',
                  '&:hover': {
                    bgcolor: '#FDEDED',
                  },
                  mr: 1,
                }}
              />
            ))}
          </Box>
          {expandReason && (
            <Box sx={{ mt: 1 }}>
              <Paper sx={{ p: 1, maxWidth: 300, bgcolor: '#FDEDED' }}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <b>Reason #{expandReasonIndex}:</b>
                  <span style={{ textDecoration: 'underline' }}>
                    {expandReason ?? ''}
                  </span>
                </Typography>
              </Paper>
            </Box>
          )}
          <CitationList citations={factCheckViolationResult.citations} />
        </CardContent>
        <CardActions sx={{ display: 'flex', gap: 1, mt: 0 }}>
          <Tooltip
            open={acceptTooltip}
            title="Unable to locate the source text within the article; the proposed modification has been copied to the clipboard"
            placement="top"
            arrow
          >
            <LoadingButton
              loading={acceptLoading}
              variant="text"
              onClick={() => handleAccept(index)}
            >
              Accept
            </LoadingButton>
          </Tooltip>
          <Tooltip
            open={copyTooltip}
            title="Copied successfully"
            placement="top"
            arrow
          >
            <Button variant="text" onClick={handleCopy}>
              Copy
            </Button>
          </Tooltip>
          <Button
            variant="text"
            color="error"
            onClick={() => {
              onResolve(index);
            }}
          >
            Ignore
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default FactCheckingResult;
