/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { documentMetrics, sentenceBreak } from '@ink-ai/dbrr-toolkit';
import htmlToMarkdown from '@wcj/html-to-markdown';
import { isOffice, searchText } from '../utils';
import { diffWords, diffChars } from 'diff';
import llamaTokenizer from 'llama-tokenizer-js';

interface TinyMceSpot {
  container: Text;
  offset: number;
}

export const asyncDocumentMetrics = (doc: string) =>
  new Promise<ReturnType<typeof documentMetrics>>((resolve) => {
    if (typeof Worker !== 'undefined') {
      const worker = new Worker(
        new URL('../../service-workers/scoreWorker.ts', import.meta.url),
        {
          type: 'module',
        },
      );
      worker.addEventListener('message', (event: MessageEvent) => {
        console.log('Worker said: ', event.data.result);
        resolve(event.data.result);
        worker.terminate();
      });

      worker.postMessage({ doc });
    } else {
      resolve(documentMetrics(doc));
    }
  });

export const asyncSentenceBreak = (doc: string) =>
  new Promise<string[]>((resolve) => {
    if (typeof Worker !== 'undefined') {
      const worker = new Worker(
        new URL(
          '../../service-workers/sentenceBreakWorker.ts',
          import.meta.url,
        ),
        {
          type: 'module',
        },
      );
      worker.addEventListener('message', (event: MessageEvent) => {
        console.log('Worker said: ', event.data.result);
        resolve(event.data.result);
        worker.terminate();
      });

      worker.postMessage({ doc });
    } else {
      resolve(sentenceBreak(doc));
    }
  });

/**
 * Represents an abstract class for a document chunk, which can be a paragraph or a table.
 */
export abstract class DocumentChunk<T = any> {
  id?: string;
  type: 'PARAGRAPH' | 'TABLE';
  _ref: T;

  constructor(body: Partial<DocumentChunk<T>>) {
    Object.assign(this, body);
  }

  setId(id: string) {
    this.id = id;
  }

  async toMarkdown() {
    const html = await this.toHTML();
    const md = await htmlToMarkdown({ html });
    return md.trimEnd();
  }

  abstract toHTML(): Promise<string>;

  /**
   * Focus and scroll to the chunk in editor
   */
  abstract focus(): Promise<void>;

  abstract findText(text: string): Promise<any | null>;

  abstract replaceText(source: string, target: string): Promise<boolean>;
}

interface ParagraphWithIndex {
  id: number;
  text: string;
  type: 'PARAGRAPH' | 'TABLE';
}

interface WordTable {
  id: number;
}

const getTinyMceDoms = () => {
  const editor = window.tinymce.activeEditor;
  if (!editor) {
    return [];
  }
  return Array.from(window.tinymce.activeEditor.getBody().childNodes).filter(
    (dom) => dom.textContent.trim() !== '',
  ) as HTMLElement[];
};

export class TinyMceDocumentChunk extends DocumentChunk<ParagraphWithIndex[]> {
  getElements() {
    const domList = getTinyMceDoms();
    const ids = this._ref.map(({ id }) => id);
    return domList.filter((_, index) => ids.includes(index));
  }
  async toHTML() {
    const htmlList = this.getElements().map((el) => el.outerHTML);
    return `<div>${htmlList.join('\n')}</div>`;
  }
  async focus() {
    const editor = window.tinymce.activeEditor;
    const elements = this.getElements();
    if (!editor || elements.length === 0) {
      return;
    }
    elements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
    const startElement = editor.selection.select(elements[0]);
    const endElement = editor.selection.select(elements[elements.length - 1]);

    if (startElement && endElement) {
      const range = editor.selection.getRng();
      range.setStartBefore(startElement);
      range.setEndAfter(endElement);

      editor.selection.setRng(range);
    }
  }

  async findText(text: string): Promise<TinyMceSpot | null> {
    const editor = window.tinymce.activeEditor;
    const elements = this.getElements();
    const matchedElement = elements.find((el) => el.textContent.includes(text));
    if (!matchedElement) {
      return null;
    }
    editor.selection.select(matchedElement);
    matchedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    const DOMUtils = window.tinymce.dom.DOMUtils;
    const domUtil = DOMUtils(matchedElement.ownerDocument, {
      root_element: matchedElement,
    });
    const seeker = window.tinymce.dom.TextSeeker(domUtil);
    const startOfWord = seeker.forwards(matchedElement, 0, (_, __, t) => {
      const index = t.indexOf(text);
      if (index !== -1) {
        return index;
      } else {
        // Not found, continue searching
        return -1;
      }
    });
    if (startOfWord === null) {
      return startOfWord;
    }
    const range = editor.dom.createRng();
    range.setStart(startOfWord.container, startOfWord.offset);
    range.setEnd(startOfWord.container, startOfWord.offset + text.length);
    editor.selection.setRng(range);
    editor.focus();
    return startOfWord;
  }

  async replaceText(source: string, target: string) {
    const spot = await this.findText(source);
    if (!spot) {
      return false;
    }
    const { container } = spot;
    if (container.nodeType === Node.TEXT_NODE) {
      const originalText = container.textContent;
      container.textContent = originalText.replace(source, target);
      return true;
    } else {
      console.error('Container is not a text node.');
      return false;
    }
  }
}

export class WordMultiParagraphChunk extends DocumentChunk<
  ParagraphWithIndex[]
> {
  type = 'PARAGRAPH' as const;
  async toHTML() {
    let html = '';
    await Word.run(async (context) => {
      const paragraphs = context.document.body.paragraphs;
      const paragraphIds = this._ref.map(({ id }) => id);
      paragraphs.load('items');
      await context.sync();
      const paragraphItems = paragraphs.items.filter((_, index) =>
        paragraphIds.includes(index),
      );
      const range = paragraphItems[0]
        .getRange('Start')
        .expandTo(paragraphItems[paragraphItems.length - 1].getRange('End'));
      const htmlRequest = range.getHtml();
      await context.sync();
      html = htmlRequest.value;
    });
    return html;
  }
  async focus() {
    await Word.run(async (context) => {
      const paragraphs = context.document.body.paragraphs;
      const paragraphIds = this._ref.map(({ id }) => id);
      paragraphs.load('items');
      await context.sync();
      const paragraphItems = paragraphs.items.filter((_, index) =>
        paragraphIds.includes(index),
      );
      const range = paragraphItems[0]
        .getRange('Start')
        .expandTo(paragraphItems[paragraphItems.length - 1].getRange('End'));
      range.select();
      await context.sync();
    });
  }

  async findTextInContext(text: string, context: Word.RequestContext) {
    const paragraphs = context.document.body.paragraphs;
    const paragraphIds = this._ref.map(({ id }) => id);
    paragraphs.load('items');
    await context.sync();
    const paragraphItems = paragraphs.items.filter((_, index) =>
      paragraphIds.includes(index),
    );
    const range = paragraphItems[0]
      .getRange('Start')
      .expandTo(paragraphItems[paragraphItems.length - 1].getRange('End'));
    return await searchText(context, text, range);
  }

  async findText(text: string) {
    let textRange: Word.Range | null = null;
    await Word.run(async (context) => {
      textRange = await this.findTextInContext(text, context);
      if (textRange) {
        textRange.select();
      }
    });
    return textRange;
  }

  async replaceText(source: string, target: string) {
    let success = false;
    await Word.run(async (context) => {
      const textRange = await this.findTextInContext(source, context);
      if (!textRange) {
        return;
      }
      textRange.insertText(target, 'Replace');
      success = true;
    });
    return success;
  }
}

export class WordTableChunk extends DocumentChunk<WordTable> {
  type = 'TABLE' as const;
  async toHTML() {
    let html = '';
    await Word.run(async (context) => {
      const tables = context.document.body.tables;
      tables.load('items');
      await context.sync();
      const table = tables.items[this._ref.id];
      if (!table) {
        console.error(`Table no.${this._ref.id} is not found!`);
        return;
      }
      const range = table.getRange('Whole');
      await context.sync();
      const htmlRequest = range.getHtml();
      await context.sync();
      html = htmlRequest.value;
    });
    return html;
  }
  async focus() {
    await Word.run(async (context) => {
      const tables = context.document.body.tables;
      tables.load('items');
      await context.sync();
      const table = tables.items[this._ref.id];
      if (!table) {
        console.error(`Table no.${this._ref.id} is not found!`);
        return;
      }
      table.select();
      await context.sync();
    });
  }

  async findText(text: string) {
    return null;
  }

  async replaceText(source: string, target: string) {
    return false;
  }
}

let documentChunkList = [] as DocumentChunk[];
(window as any).documentChunkList = documentChunkList;

export const getDocumentChunkList = () => documentChunkList;

export const setDocumentChunkList = (list: DocumentChunk[]) => {
  documentChunkList = list;
  (window as any).documentChunkList = documentChunkList;
};

export const refreshDocumentChunk = async () => {
  let newChunks: DocumentChunk[] = [];
  const paragraphChunkList: ParagraphWithIndex[][] = [];
  let currentParagraphChunk: ParagraphWithIndex[] = [];
  let currentTokenCount = 0;
  const flushChunks = () => {
    if (currentParagraphChunk.length > 0) {
      paragraphChunkList.push(currentParagraphChunk);
      currentParagraphChunk = [];
      currentTokenCount = 0;
    }
  };
  const appendChunk = (chunk: ParagraphWithIndex) => {
    const chunkToken = llamaTokenizer.encode(chunk.text).length;
    if (currentTokenCount > 0 && chunkToken + currentTokenCount > 1000) {
      flushChunks();
    }
    currentParagraphChunk.push(chunk);
    currentTokenCount += chunkToken;
  };
  // monopoly chunks like table
  const appendMonopolyChunk = (chunk: ParagraphWithIndex) => {
    flushChunks();
    appendChunk(chunk);
    flushChunks();
  };
  if (isOffice()) {
    await Word.run(async (context) => {
      const paragraphs = context.document.body.paragraphs;
      const tables = context.document.body.tables;
      paragraphs.load('items/style,items/text,items/parentTableOrNullObject');
      tables.load('items');
      await context.sync();

      const paragraphList: ParagraphWithIndex[] = paragraphs.items.map(
        (p, index) => ({
          id: index,
          text: p.text,
          type: 'PARAGRAPH',
        }),
      );
      const tableSet = new Set<Word.Table>();
      (window as any).tableSet = tableSet;
      paragraphList.forEach((p, index) => {
        if (p.text.trim() === '') {
          // filter out paragraphs that are empty
          return;
        }
        const paragraph = paragraphs.items[index];
        const table = paragraph.parentTableOrNullObject;
        // non table paragraphs
        if (table.isNullObject) {
          // heading line, start new chunk
          if (paragraph.style.startsWith('Heading')) {
            flushChunks();
          }
          appendChunk({
            id: index,
            text: paragraph.text,
            type: 'PARAGRAPH',
          });
          return;
        } else {
          // flush on table
          flushChunks();
        }
      });
      flushChunks();

      newChunks = paragraphChunkList.map(
        (paragraphs) =>
          new WordMultiParagraphChunk({
            _ref: paragraphs,
          }),
      );

      tables.items.forEach((_, index) => {
        newChunks.push(
          new WordTableChunk({
            _ref: {
              id: index,
            },
          }),
        );
      });
    });
  } else {
    const editor = window.tinymce.activeEditor;
    if (!editor) {
      newChunks = [];
    }
    const domList = getTinyMceDoms();

    const flushNodes = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];
    domList.forEach((dom, index) => {
      if (dom.nodeName === 'TABLE') {
        appendMonopolyChunk({
          id: index,
          text: dom.textContent,
          type: 'TABLE',
        });
        return;
      }
      if (flushNodes.includes(dom.nodeName)) {
        flushChunks();
      }
      appendChunk({
        id: index,
        text: dom.textContent,
        type: 'PARAGRAPH',
      });
      return;
    });
    flushChunks();

    newChunks = paragraphChunkList.map(
      (paragraphs) =>
        new TinyMceDocumentChunk({
          _ref: paragraphs,
          type: paragraphs[0].type,
        }),
    );
  }
  setDocumentChunkList(newChunks);
  return newChunks;
};

export const getCorrectionByDiff = (text: string, output: string) => {
  if (text === output) {
    return [];
  }
  // diffWords for white space splitted lang like english, diffChars for other languages like chinese.
  const group = / +/g.test(output)
    ? diffWords(text, output)
    : diffChars(text, output);
  return group;
};

(window as any).refreshDocumentChunk = refreshDocumentChunk;
