/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { CollapseCard } from '@ink-ai/portal/common/components/cards/CollapseCard';
import { copyText, insertText } from '@ink-ai/portal/common/utils';
import { RootState } from '@ink-ai/portal/reducers';
import { compose, startCompose } from '@ink-ai/portal/reducers/compose';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import StopIcon from '@mui/icons-material/Stop';
import {
  Button,
  CardContent,
  CircularProgress,
  Typography,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ViewDiff } from './components/ViewDiff';

interface GeneratedContentProps {
  showDiff?: boolean;
}

export const GeneratedContent = ({ showDiff }: GeneratedContentProps) => {
  const composeState = useSelector((state: RootState) => state.compose);
  const dispatch = useDispatch<any>();

  const originalText = composeState.taskParams?.text ?? '';
  return (
    <CollapseCard open={true}>
      <CardContent className="card-content">
        <Typography
          gutterBottom
          variant="subtitle1"
          component="div"
          color="primary"
          fontWeight="bold"
          display="flex"
          alignItems="center"
        >
          <AutoAwesomeOutlinedIcon className="mr-1" />
          <span className="flex-1">Generated Content</span>
        </Typography>
        {!composeState.generatedText && (
          <div className="text-center">
            <CircularProgress size={40} />
          </div>
        )}
        {composeState.generatedText && showDiff && (
          <ViewDiff
            originalText={originalText}
            generatedText={composeState.generatedText}
          />
        )}
        {composeState.generatedText && !showDiff && (
          <Typography gutterBottom>{composeState.generatedText}</Typography>
        )}
        {!composeState.isGenerating && composeState.generatedText && (
          <div className="flex justify-between">
            <Button
              onClick={() => dispatch(startCompose(composeState.taskParams))}
            >
              Regenerate
            </Button>
            <Button onClick={() => copyText(composeState.generatedText)}>
              Copy
            </Button>
            <Button onClick={() => insertText(composeState.generatedText)}>
              Insert
            </Button>
          </div>
        )}
        {composeState.isGenerating && (
          <Button
            color="error"
            fullWidth={true}
            onClick={() => dispatch(compose.actions.stopGenerating())}
          >
            <StopIcon className="mr-1" />
            Stop generating
          </Button>
        )}
      </CardContent>
    </CollapseCard>
  );
};
