/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  InferenceResponseDtoStatusEnum,
  InferenceResponseDtoTypeEnum,
} from '@ink-ai/insight-service-sdk';
import {
  CorrectionItem,
  SHOULD_CREATE_NEW_INF_STATUS,
} from '../reducers/correction';
import { DateTime } from 'luxon';
import { sha256 } from 'hash.js';
import copy from 'copy-to-clipboard';
import rawGlossaryJsonValidate from './utils/validate_glossary_schema';

export const SHA256Digest = async (message: string) =>
  sha256().update(message).digest('hex');

/**
 * The function `getInfAndCorrections` takes in an array of original inferences, an array of original
 * correction items, and an optional type parameter. It filters the original inferences based on
 * whether they already exist in the correction items array, and returns the filtered inferences and a
 * new array of correction items.
 * @param {{ text: string; id: string }[]} originInf - An array of objects representing the original
 * inferences. Each object has two properties: "text" (string) and "id" (string).
 * @param {CorrectionItem[]} originState - An array of CorrectionItem objects. Each CorrectionItem
 * object has the following properties:
 * @param type - The `type` parameter is an optional parameter that specifies the type of inference. It
 * is set to `INFERENCE_TYPE.LANGUAGE` by default.
 * @returns an object with two properties: "infList" and "corrections". "infList" is an array of
 * objects that passed the filter condition, and "corrections" is an array of new correction items.
 */
export const getInfAndCorrections = (
  originInf: { text: string; id: string; hash: string }[],
  originState: CorrectionItem[],
  type: InferenceResponseDtoTypeEnum = InferenceResponseDtoTypeEnum.Language,
) => {
  let correctionItems = Array.from(originState);
  const newCorrectionItems = [] as CorrectionItem[];
  const infList = originInf.filter((inf) => {
    let foundButShouldUpdate = false;
    const foundExistingValidCorrection = correctionItems.some((item, index) => {
      let match = false;
      if (item.hash === inf.hash) {
        match = true;
        if (SHOULD_CREATE_NEW_INF_STATUS.includes(item.status)) {
          foundButShouldUpdate = true;
        }
      }
      if (match) {
        if (foundButShouldUpdate) {
          // should inf due to status, push placeholder
          newCorrectionItems.push({
            id: item.id,
            text: inf.text,
            status: InferenceResponseDtoStatusEnum.Submitting,
            type,
            corrections: [],
            createdAt: DateTime.now().toUnixInteger(),
            requestedAt: DateTime.now().toUnixInteger(),
            description: '',
            hash: inf.hash,
          });
        } else {
          // should not inf due to already exists, push existing
          newCorrectionItems.push(item);
        }
        correctionItems = correctionItems.slice(index + 1);
      }
      return match;
    });
    if (!foundExistingValidCorrection) {
      // should inf due to not found, push place holder
      newCorrectionItems.push({
        id: inf.id,
        text: inf.text,
        status: InferenceResponseDtoStatusEnum.Submitting,
        type,
        corrections: [],
        createdAt: DateTime.now().toUnixInteger(),
        requestedAt: DateTime.now().toUnixInteger(),
        description: '',
        hash: inf.hash,
      });
    }
    return !foundExistingValidCorrection || foundButShouldUpdate;
  });
  return {
    infList,
    corrections: newCorrectionItems,
  };
};

const searchTextUnder200 = async (
  context: Word.RequestContext,
  text: string,
  range?: Word.Range,
) => {
  const body = range ?? context.document.body;
  const options = Word.SearchOptions.newObject(context);
  options.matchCase = true;
  const searchResults = body.search(text, options);
  context.load(searchResults, 'text, font');

  await context.sync();

  return searchResults.items.shift();
};

export const searchText = async (
  context: Word.RequestContext,
  text: string,
  range?: Word.Range,
) => {
  if (text.length <= 200) {
    return await searchTextUnder200(context, text, range);
  } else {
    const rangeStart = await searchTextUnder200(
      context,
      text.substring(0, 200),
      range,
    );
    const rangeEnd = await searchTextUnder200(
      context,
      text.substring(text.length - 200),
      range,
    );
    return rangeStart.expandTo(rangeEnd);
  }
};

export const selectText = async (
  context: Word.RequestContext,
  text: string,
) => {
  const range = await searchText(context, text);
  range.select(Word.SelectionMode.select);
  await context.sync();
};

export const concurrentConsume =
  <T, U>(consumer: (input: T) => Promise<U>, concurrency: number) =>
  async (input: T[]) => {
    const totalRes = await Promise.all(
      Array.from({ length: concurrency }).map(async () => {
        const res: U[] = [];
        while (input.length > 0) {
          const item = input.shift();
          res.push(await consumer(item));
        }
        return res;
      }),
    );
    return totalRes.flat();
  };

export const sleep = (time: number) =>
  new Promise((r) => {
    setTimeout(() => {
      r(true);
    }, time);
  });

export const isInternetExplorer = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');
  return msie > 0 || trident > 0;
};

export const copyText = (text: string) => {
  copy(text, {
    debug: true,
  });
};

export const insertText = (text: string) => {
  if (isOffice()) {
    Word.run(async (context) => {
      const doc = context.document;
      const originalRange = doc.getSelection();
      originalRange.insertText(text, Word.InsertLocation.end);
    });
  } else if (window.tinymce.activeEditor) {
    window.tinymce.activeEditor.insertContent(text);
  }
};

export const downloadFile = (
  fileContent: string | Blob,
  fileName: string,
  fileType: string,
) => {
  const element = document.createElement('a');
  const file =
    fileContent instanceof Blob
      ? fileContent
      : new Blob([fileContent], { type: fileType });

  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const formatFileSize = (size) => {
  if (size < 1024) return `${size} bytes`;
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} kb`;
  if (size < 1024 * 1024 * 1024)
    return `${(size / (1024 * 1024)).toFixed(2)} mb`;
  return `${(size / (1024 * 1024 * 1024)).toFixed(2)} gb`;
};

export const formatDate = (timestamp: number | undefined) => {
  if (timestamp === undefined) {
    return '-';
  }

  return DateTime.fromMillis(timestamp)
    .setLocale('zh-CN')
    .toFormat('yyyy/MM/dd HH:mm:ss');
};

interface GlossaryJsonValidate {
  (
    data: any,
    dataPath?: any,
    parentData?: any,
    parentDataProperty?: any,
    rootData?: any,
  ): boolean;
  errors: any;
}
const glossaryJsonValidate = rawGlossaryJsonValidate as GlossaryJsonValidate;
export { glossaryJsonValidate };

export const isOffice = () => {
  return !!window.Office?.context?.host;
};
