/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { getStore } from '@ink-ai/portal/reducers';
import { common } from '@ink-ai/portal/reducers/common';
import { useEffect } from 'react';
import { isOffice } from '../utils';
import htmlToMarkdown from '@wcj/html-to-markdown';

const handleSelectionChange = async () => {
  const store = getStore();
  if (isOffice()) {
    Office.context.document.getSelectedDataAsync(
      Office.CoercionType.Text,
      (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          store.dispatch(
            common.actions.setSelectedText(result.value as string),
          );
        }
      },
    );
    Office.context.document.getSelectedDataAsync(
      Office.CoercionType.Html,
      async (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const html = result.value as string;
          const markdown =
            html.trim() === '' ? '' : (await htmlToMarkdown({ html })).trim();
          store.dispatch(common.actions.setSelectedMarkdown(markdown));
        }
      },
    );
  } else if (window.tinymce?.activeEditor) {
    store.dispatch(
      common.actions.setSelectedText(
        window.tinymce.activeEditor.selection?.getContent({ format: 'text' }) ??
          '',
      ),
    );
    const html =
      window.tinymce.activeEditor.selection?.getContent({ format: 'html' }) ??
      '';
    const markdown = html === '' ? '' : await htmlToMarkdown({ html });
    store.dispatch(common.actions.setSelectedMarkdown(markdown));
  }
};

export const useSyncSelectedData = () => {
  useEffect(() => {
    const interval = setInterval(handleSelectionChange, 1000);

    // Clean up the event listener on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);
};
