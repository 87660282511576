/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  FactCheckResultDto,
  FactualStatementDto,
  FactViolationDto,
  RuleCheckResultDto,
  ViolationDto,
} from '@ink-ai/insight-service-sdk';
import { getDocumentChunkList } from '../common/utils/document.util';

export type InspectStatusType = 'idle' | 'checking' | 'paused';

export type InspectType = 'rule' | 'fact';

export type InspectState = {
  inspectType: InspectType;
  scanType: string;
  selectedRules: string[];
  totalViolationCount: number;
  totalFactViolationCount: number;
  totalFactFactualCount: number;
  ruleCheckResult: {
    chunkTaskId: string;
    result: ViolationDto;
  }[];
  factCheckViolationResult: {
    chunkTaskId: string;
    result: FactViolationDto;
  }[];
  factCheckFactualResult: {
    chunkTaskId: string;
    result: FactualStatementDto;
  }[];
  ruleEmptyError: boolean;
  currentChunkTaskId: string;
  taskIndexMap: Record<string, number>;
  currentFactChunkTaskId: string;
  currentFactTaskIndexMap: Record<string, number>;
  totalRuleCheckingTask: number;
  totalFactCheckingTask: number;
  submittedRuleCheckingTask: number;
  submittedFactCheckingTask: number;
  completedRuleCheckingTask: number;
  completedFactCheckingTask: number;
  ruleCheckingStatus: InspectStatusType;
  factCheckingStatus: InspectStatusType;
  useAdditionalSource: boolean;
  inputContentEmptyError: boolean;
};

const initialState: InspectState = {
  inspectType: 'rule',
  scanType: 'entire',
  selectedRules: [],
  totalViolationCount: 0,
  totalFactViolationCount: 0,
  totalFactFactualCount: 0,
  ruleCheckResult: [],
  factCheckViolationResult: [],
  factCheckFactualResult: [],
  ruleEmptyError: false,
  currentChunkTaskId: '',
  taskIndexMap: {},
  currentFactChunkTaskId: '',
  currentFactTaskIndexMap: {},
  totalRuleCheckingTask: 0,
  totalFactCheckingTask: 0,
  submittedRuleCheckingTask: 0,
  submittedFactCheckingTask: 0,
  completedRuleCheckingTask: 0,
  completedFactCheckingTask: 0,
  ruleCheckingStatus: 'idle',
  factCheckingStatus: 'idle',
  useAdditionalSource: false,
  inputContentEmptyError: false,
};

export const inspect = createSlice({
  name: 'inspect',
  initialState,
  reducers: {
    resetInspectState: () => {
      return initialState;
    },
    changeInspectType: (state, { payload }: PayloadAction<InspectType>) => {
      state.inspectType = payload;
    },
    changeSelectedRules: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedRules = payload;
      state.ruleEmptyError = payload.length === 0;
    },
    changeRuleEmptyError: (state, { payload }: PayloadAction<boolean>) => {
      state.ruleEmptyError = payload;
    },
    changeScanType: (state, { payload }: PayloadAction<string>) => {
      state.scanType = payload;
    },
    resetRuleCheckResult: (state) => {
      state.ruleCheckResult = [];
      state.totalViolationCount = 0;
      state.submittedRuleCheckingTask = 0;
      state.completedRuleCheckingTask = 0;
    },
    resetFactCheckResult: (state) => {
      state.factCheckViolationResult = [];
      state.factCheckFactualResult = [];
      state.totalFactViolationCount = 0;
      state.totalFactFactualCount = 0;
      state.submittedFactCheckingTask = 0;
      state.completedFactCheckingTask = 0;
    },
    onRuleCheckTaskFail: (state) => {
      state.completedRuleCheckingTask++;
      if (state.completedRuleCheckingTask === state.totalRuleCheckingTask) {
        state.ruleCheckingStatus = 'idle';
      }
    },
    onFactCheckTaskFail: (state) => {
      state.completedFactCheckingTask++;
      if (state.completedFactCheckingTask === state.totalFactCheckingTask) {
        state.factCheckingStatus = 'idle';
      }
    },

    setCurrentChunkTaskId: (state, { payload }: PayloadAction<string>) => {
      state.currentChunkTaskId = payload;
    },
    focusRuleCheckResult: (state, { payload }: PayloadAction<number>) => {
      const currentRes = state.ruleCheckResult[payload];
      const currentChunkIndex = state.taskIndexMap[currentRes.chunkTaskId];
      const documentChunkList = getDocumentChunkList();
      const chunk = documentChunkList[currentChunkIndex];
      if (currentRes && chunk) {
        chunk?.focus();
        chunk?.findText(currentRes.result.originContent);
      }
    },
    setCurrentFactChunkTaskId: (state, { payload }: PayloadAction<string>) => {
      state.currentFactChunkTaskId = payload;
    },
    updateRuleCheckResult: (
      state,
      { payload }: PayloadAction<RuleCheckResultDto>,
    ) => {
      if (!payload.result.violationList?.length) {
        return;
      }
      const currentIndex = state.taskIndexMap[payload.id];
      if (currentIndex === undefined) {
        return;
      }

      // update rule checking progress
      state.completedRuleCheckingTask++;
      if (state.completedRuleCheckingTask === state.totalRuleCheckingTask) {
        state.ruleCheckingStatus = 'idle';
      }

      // find insert index
      let insertIndex = 0;
      for (let i = 0; i < state.ruleCheckResult.length; i++) {
        const resultIndex =
          state.taskIndexMap[state.ruleCheckResult[i].chunkTaskId];
        if (resultIndex > currentIndex) {
          insertIndex = i;
          break;
        }
        insertIndex = i + 1;
      }

      // insert violation list
      payload.result.violationList.forEach((violation) => {
        state.ruleCheckResult.splice(insertIndex++, 0, {
          chunkTaskId: payload.id,
          result: violation,
        });
      });
      state.totalViolationCount += payload.result?.violationList?.length ?? 0;
    },
    updateFactCheckViolationResult: (
      state,
      { payload }: PayloadAction<FactCheckResultDto>,
    ) => {
      const currentIndex = state.currentFactTaskIndexMap[payload.id];
      if (currentIndex === undefined) {
        return;
      }

      // update fact checking progress
      state.completedFactCheckingTask++;
      if (state.completedFactCheckingTask === state.totalFactCheckingTask) {
        state.factCheckingStatus = 'idle';
      }

      if (payload.result.violations) {
        let violationInsertIndex = 0;
        for (let i = 0; i < state.factCheckViolationResult.length; i++) {
          const resultIndex =
            state.currentFactTaskIndexMap[
              state.factCheckViolationResult[i].chunkTaskId
            ];
          if (resultIndex > currentIndex) {
            violationInsertIndex = i;
            break;
          }
          violationInsertIndex = i + 1;
        }

        payload.result.violations.forEach((violation) => {
          state.factCheckViolationResult.splice(violationInsertIndex++, 0, {
            chunkTaskId: payload.id,
            result: violation,
          });
        });
        state.totalFactViolationCount += payload.result.violations.length;
      }

      if (payload.result.factualStatements) {
        let factualInsertIndex = 0;
        for (let i = 0; i < state.factCheckFactualResult.length; i++) {
          const resultIndex =
            state.currentFactTaskIndexMap[
              state.factCheckFactualResult[i].chunkTaskId
            ];
          if (resultIndex > currentIndex) {
            factualInsertIndex = i;
            break;
          }
          factualInsertIndex = i + 1;
        }

        payload.result.factualStatements.forEach((factualStatement) => {
          state.factCheckFactualResult.splice(factualInsertIndex++, 0, {
            chunkTaskId: payload.id,
            result: factualStatement,
          });
        });
        state.totalFactFactualCount += payload.result.factualStatements.length;
      }
    },

    updateTaskIndexMap: (
      state,
      { payload }: PayloadAction<{ taskId: string; index: number }>,
    ) => {
      state.taskIndexMap[payload.taskId] = payload.index;
    },

    updateFactTaskIndexMap: (
      state,
      { payload }: PayloadAction<{ taskId: string; index: number }>,
    ) => {
      state.currentFactTaskIndexMap[payload.taskId] = payload.index;
    },

    resolveRuleCheckResult: (state, { payload }: PayloadAction<number>) => {
      state.ruleCheckResult = state.ruleCheckResult.filter(
        (_, index) => index !== payload,
      );
      state.totalViolationCount = Math.max(0, state.totalViolationCount - 1);
    },

    resolveFactCheckResult: (state, { payload }: PayloadAction<number>) => {
      state.factCheckViolationResult = state.factCheckViolationResult.filter(
        (_, index) => index !== payload,
      );
      state.totalFactViolationCount = Math.max(
        0,
        state.totalFactViolationCount - 1,
      );
    },

    setTotalRuleCheckingTask: (state, { payload }: PayloadAction<number>) => {
      state.totalRuleCheckingTask = payload;
    },

    submittedRuleCheckTask: (state) => {
      state.submittedRuleCheckingTask++;
    },

    setTotalFactCheckingTask: (state, { payload }: PayloadAction<number>) => {
      state.totalFactCheckingTask = payload;
    },

    submittedFactCheckTask: (state) => {
      state.submittedFactCheckingTask++;
    },

    setRuleCheckingStatus: (
      state,
      { payload }: PayloadAction<InspectStatusType>,
    ) => {
      state.ruleCheckingStatus = payload;
    },

    setFactCheckingStatus: (
      state,
      { payload }: PayloadAction<InspectStatusType>,
    ) => {
      state.factCheckingStatus = payload;
    },

    setUseAdditionalSource: (state, { payload }: PayloadAction<boolean>) => {
      state.useAdditionalSource = payload;
    },

    setInputContentEmptyError: (state, { payload }: PayloadAction<boolean>) => {
      state.inputContentEmptyError = payload;
    },
  },
});

export const inspectActions = inspect.actions;
export default inspect.reducer;
