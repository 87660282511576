/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { Box } from '@mui/system';
import React, { useState } from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';

interface StickyContainerProps {
  count: number;
  children: React.ReactNode;
  title: string;
  type: 'red' | 'green';
}

const StickyContainer: React.FC<StickyContainerProps> = ({
  title,
  count,
  children,
  type,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
      <Box sx={{ position: 'sticky', top: 0, zIndex: 40 }}>
        <Box
          //   expandIcon={<ExpandMoreIcon sx={{ color: '#2196F3' }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '4px',
            paddingRight: '4px',
            backgroundColor: type === 'red' ? '#FDF7F7' : '#F7F9F6',
            color: type === 'red' ? '#D32F2F' : 'green',
            height: 48,
            minHeight: 48,
            '&.Mui-expanded': {
              height: 48,
              minHeight: 48,
            },
          }}
        >
          <Box>
            {type === 'red' && <ErrorOutlineOutlinedIcon sx={{ mr: '2px' }} />}
            {type === 'green' && <CheckCircleOutlineIcon sx={{ mr: '2px' }} />}
            {title}
          </Box>
          <Box
            component="span"
            sx={{
              fontSize: '14px',
              lineHeight: '23px',
              bgcolor: type === 'red' ? '#D32F2F' : 'green',
              color: 'white',
              borderRadius: '18px',
              px: 1,
              py: 0,
              ml: 0.5,
            }}
          >
            {count}
          </Box>
          <ExpandMoreIcon
            sx={{
              cursor: 'pointer',
              color: type === 'red' ? '#D32F2F' : 'green',
              transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </Box>
      </Box>
      <Collapse in={isExpanded} sx={{ px: 1, py: 0 }}>
        {children}
      </Collapse>
    </Box>
  );
};

export default StickyContainer;
