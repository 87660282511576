/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState } from 'react';
import { CitationDto } from '@ink-ai/insight-service-sdk';
import { Chip, Divider, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { useDispatch } from 'react-redux';
import { article } from '@ink-ai/portal/reducers/article';
import {
  aiAssistant,
  ASSISTANT_TABS,
} from '@ink-ai/portal/reducers/ai-assistant';
interface CitationListProps {
  citations: CitationDto[];
}

const CitationList: React.FC<CitationListProps> = ({ citations }) => {
  const dispatch = useDispatch<any>();
  const [expandCitation, setExpandCitation] = useState<CitationDto>(null);
  const [expandCitationIndex, setExpandCitationIndex] = useState(null);
  return (
    <Box
      sx={{
        mt: 1,
        pt: 1,
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
      }}
    >
      <Divider sx={{ width: '100%' }} />
      {citations?.map((item) => (
        <Box key={item.citationId}>
          <Chip
            size="small"
            icon={<AutoStoriesIcon color="primary" />}
            label={`Citation #${item.citationId}`}
            onClick={() => {
              if (expandCitation === item) {
                setExpandCitation(null);
                setExpandCitationIndex(null);
              } else {
                setExpandCitation(item);
                setExpandCitationIndex(item.citationId);
              }
            }}
            sx={{
              cursor: 'pointer',
              bgcolor: 'white',
              border: '1px solid #1976d2',
              color: '#1976d2',
              '&:hover': {
                bgcolor: '#e8f5fe',
              },
            }}
          />
        </Box>
      ))}
      {expandCitation && (
        <Box sx={{ mt: 1 }}>
          <Paper sx={{ p: 1, maxWidth: 300, bgcolor: '#e8f5fe' }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              <b>Citation #{expandCitationIndex}:</b>
              {expandCitation.relevantContent ?? ''}{' '}
              <span
                role="button"
                onClick={() => {
                  dispatch(
                    aiAssistant.actions.setCurrentTab(ASSISTANT_TABS.CITATION),
                  );
                  dispatch(
                    article.actions.setCurrentCitationId(
                      expandCitation.citationId,
                    ),
                  );
                }}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#1976d2',
                }}
              >
                [Citation details]
              </span>
            </Typography>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default CitationList;
