/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { RootState } from '@ink-ai/portal/reducers';
import {
  validateRuleCollection,
  writingRuleActions,
} from '@ink-ai/portal/reducers/wirting-rule';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { LoadingButton } from '@mui/lab';
import { Link, useNavigate } from 'react-router-dom';
import { getApi } from '@ink-ai/portal/common/requestHelper';
import { WritingRuleApi } from '@ink-ai/insight-service-sdk';
import { isNotEmpty } from 'ramda';

export const WritingRuleEditor = () => {
  const dispatch = useDispatch<any>();
  const ruleState = useSelector((state: RootState) => state.writingRule);
  const [saving, setSaving] = useState(false);
  const { writingRuleCollection } = ruleState;
  const { rules } = writingRuleCollection;
  const navigate = useNavigate();
  const onSaveClick = async () => {
    const { writingRuleCollection } = ruleState;
    const ruleCollectionValid = validateRuleCollection(
      writingRuleCollection,
      dispatch,
    );
    if (!ruleCollectionValid || saving) {
      return;
    }
    setSaving(true);
    try {
      const api = await getApi(WritingRuleApi);
      if (!writingRuleCollection.id) {
        // create new
        await api.createRuleCollection({
          title: writingRuleCollection.title,
          description: writingRuleCollection.description,
          rules: writingRuleCollection.rules.map((rule) => {
            return {
              definition: rule.definition,
              positiveExamples: rule.positiveExamples.filter(isNotEmpty),
              negativeExamples: rule.negativeExamples.filter(isNotEmpty),
            };
          }),
        });
      } else {
        // update existing
        await api.updateRuleCollection(writingRuleCollection.id, {
          title: writingRuleCollection.title,
          description: writingRuleCollection.description,
          rules: writingRuleCollection.rules.map((rule) => {
            return {
              id: rule.id,
              definition: rule.definition,
              positiveExamples: rule.positiveExamples.filter(isNotEmpty),
              negativeExamples: rule.negativeExamples.filter(isNotEmpty),
            };
          }),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
    navigate('/writing-rule');
  };
  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', gap: 4, flexDirection: 'column' }}>
          <Box sx={{ width: '70%' }}>
            <Typography variant="h5" sx={{ textAlign: 'left' }}>
              {writingRuleCollection.id ? 'Update' : 'Create'} writing rules
            </Typography>
            <Typography
              color="text.secondary"
              variant="subtitle2"
              sx={{ mb: 3, textAlign: 'left' }}
            >
              Add a set of rules for rule check
            </Typography>
            <Typography
              sx={{ mb: 1, textAlign: 'left' }}
              variant="h6"
              component="h2"
            >
              General
            </Typography>
            <TextField
              error={!!ruleState.writingRuleCollection.titleError}
              label="Name"
              variant="outlined"
              placeholder="Input a name"
              fullWidth
              sx={{
                mb: 2,
              }}
              value={writingRuleCollection.title}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              onChange={(e) => {
                dispatch(writingRuleActions.changeTitle(e.target.value));
              }}
              helperText="Maximum 1000 characters."
            />
            <TextField
              // error={descriptionError}
              label="Description (Optional)"
              placeholder="Input a description"
              variant="outlined"
              fullWidth
              sx={{ mb: 1 }}
              value={ruleState.writingRuleCollection.description}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              onChange={(e) => {
                dispatch(writingRuleActions.changeDescription(e.target.value));
              }}
              helperText="Maximum 2048 characters."
            />
          </Box>
          <Box>
            <Typography
              sx={{ mb: 1, textAlign: 'left' }}
              variant="h6"
              component="h2"
            >
              Add Rules
            </Typography>
            {rules.map((rule, ruleIndex) => (
              <Box key={ruleIndex} sx={{ position: 'relative', mb: 2 }}>
                <Card>
                  <CardContent sx={{ display: 'flex' }}>
                    <Chip label={ruleIndex + 1} color="primary" />
                    <div className="flex-1 ml-3">
                      <TextField
                        error={!!rule.definitionError}
                        label="Definition"
                        placeholder="Input the definition of rule"
                        variant="outlined"
                        multiline
                        minRows={2}
                        fullWidth
                        sx={{ mb: 1 }}
                        value={rule.definition}
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                        onChange={(e) => {
                          dispatch(
                            writingRuleActions.changeRuleDefinition({
                              index: ruleIndex,
                              definition: e.target.value,
                            }),
                          );
                        }}
                        helperText="Provide a concise and concrete description that LLM can follow, avoid being too general."
                      />
                      <Grid container spacing={2}>
                        <Grid size={6}>
                          {rule.negativeExamples.map(
                            (example, exampleIndex) => (
                              <div key={exampleIndex} className="relative">
                                <TextField
                                  label="Non-compliant example - optional"
                                  variant="outlined"
                                  multiline
                                  minRows={2}
                                  fullWidth
                                  sx={{ mb: 1 }}
                                  value={example}
                                  slotProps={{
                                    inputLabel: {
                                      shrink: true,
                                    },
                                  }}
                                  onChange={(e) => {
                                    dispatch(
                                      writingRuleActions.changeNegativeExample({
                                        ruleIndex,
                                        exampleIndex,
                                        example: e.target.value,
                                      }),
                                    );
                                  }}
                                />
                                <RemoveCircleRoundedIcon
                                  color="error"
                                  sx={{
                                    position: 'absolute',
                                    top: '-0.5rem',
                                    right: '-0.5rem',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    dispatch(
                                      writingRuleActions.removeNegativeExample({
                                        ruleIndex,
                                        exampleIndex,
                                      }),
                                    )
                                  }
                                />
                              </div>
                            ),
                          )}
                          <Button
                            fullWidth
                            onClick={() =>
                              dispatch(
                                writingRuleActions.addNegativeExample(
                                  ruleIndex,
                                ),
                              )
                            }
                          >
                            + Add non-compliant Example
                          </Button>
                        </Grid>
                        <Grid size={6}>
                          {rule.positiveExamples.map(
                            (example, exampleIndex) => (
                              <div key={exampleIndex} className="relative">
                                <TextField
                                  label="Compliant example - optional"
                                  variant="outlined"
                                  multiline
                                  minRows={2}
                                  fullWidth
                                  sx={{ mb: 1 }}
                                  value={example}
                                  slotProps={{
                                    inputLabel: {
                                      shrink: true,
                                    },
                                  }}
                                  onChange={(e) => {
                                    dispatch(
                                      writingRuleActions.changePositiveExample({
                                        ruleIndex,
                                        exampleIndex,
                                        example: e.target.value,
                                      }),
                                    );
                                  }}
                                />
                                <RemoveCircleRoundedIcon
                                  color="error"
                                  sx={{
                                    position: 'absolute',
                                    top: '-0.5rem',
                                    right: '-0.5rem',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    dispatch(
                                      writingRuleActions.removePositiveExample({
                                        ruleIndex,
                                        exampleIndex,
                                      }),
                                    )
                                  }
                                />
                              </div>
                            ),
                          )}
                          <Button
                            fullWidth
                            onClick={() =>
                              dispatch(
                                writingRuleActions.addPositiveExample(
                                  ruleIndex,
                                ),
                              )
                            }
                          >
                            + Add compliant Example
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                </Card>
                {ruleIndex !== 0 && (
                  <RemoveCircleRoundedIcon
                    color="error"
                    sx={{
                      position: 'absolute',
                      top: '-0.5rem',
                      right: '-0.5rem',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      dispatch(writingRuleActions.removeRule(ruleIndex))
                    }
                  />
                )}
              </Box>
            ))}
            <Button
              variant="outlined"
              onClick={() => dispatch(writingRuleActions.addRule())}
            >
              + New rule
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-start',
          }}
        >
          <LoadingButton
            disabled={saving}
            loading={saving}
            variant="contained"
            color="primary"
            sx={{ width: '99px' }}
            onClick={onSaveClick}
          >
            {ruleState.writingRuleCollection.id ? 'Update' : 'Save'}
          </LoadingButton>
          <Button sx={{ ml: 1, width: '99px' }}>
            <Link to="/writing-rule">Cancel</Link>
          </Button>
        </Box>
      </Paper>
    </>
  );
};
