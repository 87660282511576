/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { getApi } from '@ink-ai/portal/common/requestHelper';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { WritingRuleApi } from '@ink-ai/insight-service-sdk';

export interface WritingRule {
  id?: string;
  definition: string;
  definitionError?: string;
  positiveExamples: string[];
  negativeExamples: string[];
}

export type WritingRuleState = {
  writingRuleCollection: {
    id?: string;
    title: string;
    titleError?: string;
    description: string;
    rules: WritingRule[];
  };
  loading: boolean;
};

const initialState: WritingRuleState = {
  writingRuleCollection: {
    title: '',
    description: '',
    rules: [
      {
        definition: '',
        positiveExamples: [],
        negativeExamples: [],
      },
    ] as WritingRule[],
  },
  loading: false,
};

export const fetchWritingRule = createAsyncThunk(
  'writingRule/fetchWritingRule',
  async (id: string) => {
    const api = await getApi(WritingRuleApi);
    const res = await api.getRuleCollection(id);
    return res.data;
  },
);

const validateNoneEmptyText = (text?: string) =>
  text ? undefined : 'This field should not be empty';

export const validateRuleCollection = (
  writingRuleCollection: WritingRuleState['writingRuleCollection'],
  dispatch: any,
) => {
  let ruleCollectionValid = true;
  const { title, rules } = writingRuleCollection;
  const titleError = validateNoneEmptyText(title);
  if (titleError) {
    dispatch(writingRuleActions.setTitleError(titleError));
    ruleCollectionValid = false;
  } else {
    dispatch(writingRuleActions.setTitleError(undefined));
  }
  rules.forEach((rule, index) => {
    const definitionError = validateNoneEmptyText(rule.definition);
    if (definitionError) {
      dispatch(
        writingRuleActions.setRuleDefinitionError({
          ruleIndex: index,
          definitionError,
        }),
      );
      ruleCollectionValid = false;
    } else {
      dispatch(
        writingRuleActions.setRuleDefinitionError({
          ruleIndex: index,
          definitionError: undefined,
        }),
      );
    }
  });
  return ruleCollectionValid;
};

export const writingRule = createSlice({
  name: 'writingInstruction',
  initialState,
  reducers: {
    resetAll: () => {
      return initialState;
    },
    setTitleError: (state, { payload }: PayloadAction<string | undefined>) => {
      state.writingRuleCollection.titleError = payload;
    },
    setRuleDefinitionError: (
      state,
      {
        payload,
      }: PayloadAction<{
        ruleIndex: number;
        definitionError: string | undefined;
      }>,
    ) => {
      state.writingRuleCollection.rules[payload.ruleIndex].definitionError =
        payload.definitionError;
    },
    changeTitle: (state, { payload }: PayloadAction<string>) => {
      state.writingRuleCollection.title = payload;
    },
    changeDescription: (state, { payload }: PayloadAction<string>) => {
      state.writingRuleCollection.description = payload;
    },
    changeRuleDefinition: (
      state,
      { payload }: PayloadAction<{ index: number; definition: string }>,
    ) => {
      state.writingRuleCollection.rules[payload.index].definition =
        payload.definition;
    },
    addNegativeExample: (state, { payload }: PayloadAction<number>) => {
      state.writingRuleCollection.rules[payload].negativeExamples.push('');
    },
    removeNegativeExample: (
      state,
      { payload }: PayloadAction<{ ruleIndex: number; exampleIndex: number }>,
    ) => {
      state.writingRuleCollection.rules[
        payload.ruleIndex
      ].negativeExamples.splice(payload.exampleIndex, 1);
    },
    changeNegativeExample: (
      state,
      {
        payload,
      }: PayloadAction<{
        ruleIndex: number;
        exampleIndex: number;
        example: string;
      }>,
    ) => {
      state.writingRuleCollection.rules[payload.ruleIndex].negativeExamples[
        payload.exampleIndex
      ] = payload.example;
    },
    addPositiveExample: (state, { payload }: PayloadAction<number>) => {
      state.writingRuleCollection.rules[payload].positiveExamples.push('');
    },
    removePositiveExample: (
      state,
      { payload }: PayloadAction<{ ruleIndex: number; exampleIndex: number }>,
    ) => {
      state.writingRuleCollection.rules[
        payload.ruleIndex
      ].positiveExamples.splice(payload.exampleIndex, 1);
    },
    changePositiveExample: (
      state,
      {
        payload,
      }: PayloadAction<{
        ruleIndex: number;
        exampleIndex: number;
        example: string;
      }>,
    ) => {
      state.writingRuleCollection.rules[payload.ruleIndex].positiveExamples[
        payload.exampleIndex
      ] = payload.example;
    },
    addRule: (state) => {
      state.writingRuleCollection.rules.push({
        definition: '',
        positiveExamples: [],
        negativeExamples: [],
      });
    },
    removeRule: (state, { payload }: PayloadAction<number>) => {
      if (state.writingRuleCollection.rules.length > 1) {
        state.writingRuleCollection.rules.splice(payload, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWritingRule.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWritingRule.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.writingRuleCollection = {
          id: payload.id,
          title: payload.title,
          description: payload.description,
          rules: payload.rules.map((rule) => {
            return {
              id: rule.id,
              definition: rule.definition,
              positiveExamples: rule.positiveExamples,
              negativeExamples: rule.negativeExamples,
            };
          }),
        };
      })
      .addCase(fetchWritingRule.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const writingRuleActions = writingRule.actions;
